import React, { useEffect, useState } from 'react';
import axios from 'axios';
import api from "../api"
import { v4 as uuidv4 } from 'uuid'; // UUID 생성을 위해 추가

const Payment = () => {
  const [amount, setAmount] = useState(0);
  const [email, setEmail] = useState('');
  const { IMP } = window;
  const token = localStorage.getItem("bbs_access_token");
  const memberNo = localStorage.getItem("id");

  useEffect(() => {
    console.log(token);
    const nickname = localStorage.getItem("nickname");
    setEmail(localStorage.getItem("email"));
    // 이하 생략
  }, []);

  const generateIdempotencyKey = (methodType, memberId, amount) => {
    // memberId와 amount를 조합하여 고유한 키 생성
    return `${methodType}-${memberId}-${amount}`;
  };

  const requestPay = async () => {
    const idempotencyKey = generateIdempotencyKey("charge", memberNo, amount);
    
    IMP.init('imp66118428');

    IMP.request_pay({
      pg: 'kakaopay.TC0ONETIME',
      pay_method: 'card',
      merchant_uid: new Date().getTime(),
      name: '식구 페이 충전',
      amount: amount,
      buyer_email: email,
    }, async (rsp) => {
      console.log(rsp);
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL}/plant-pay-service/verifyIamport/${rsp.imp_uid}`, 
          {},
          {
            headers: { 
              Authorization: `Bearer ${token}`,
              'Idempotency-Key': idempotencyKey // Idempotency Key를 헤더에 추가
            }
          }
        );

        if (rsp.paid_amount === data.response.amount) {
          alert('결제 성공');
          // 결제 성공 후 서버에 결제 정보 전송
          await api.post(
            `${process.env.REACT_APP_SERVER_URL}/plant-pay-service/payMoney`,
            {
              paymentRequestDto: {
                payMoney: amount,
                memberNo: memberNo
              }
            },
            {
              headers: { 
                Authorization: `Bearer ${token}`,
                'Idempotency-Key': idempotencyKey // 동일한 Idempotency Key 사용
              }
            }
          );
        } else {
          alert('결제 실패');
        }
      } catch (error) {
        console.error('Error while verifying payment:', error);
        alert('결제 실패');
      }
    });
  };

  return (
    <div>
      <input
        type="number"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder="금액을 입력하세요"
      />
      <button onClick={requestPay}>충전하기</button>
    </div>
  );
};

export default Payment;